<template>
  <v-container>
    <v-card class="mx-auto mt-5" max-width="800">
      <v-row justify="center" class="mt-5">
        <img src="/static/logo_phisi.svg" style="width: 15%" class="mt-4" />
      </v-row>
      <v-row>
        <v-col>
          <div class="text-h4 text-center">PhisiEMR</div>
          <div class="text--secondary text-center text-h5">
            Sistema de Consulta de Exámenes
          </div>
          <div class="text--secondary text-center text-h5">
            {{ clientName }}
          </div>
          <v-divider class="ma-4"> </v-divider>

          <v-row align="center" class="mx-2">
            <v-col md="6" offset-md="3" cols="12">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="pet_code"
                    label="Código del paciente"
                    name="pet_code"
                    append-icon="mdi-search"
                    type="text"
                    required
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                Ingrese código de Paciente (formato: XXXXXXXX)
              </v-tooltip>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row v-if="showConditions == 'True'">
            <v-col md="6" offset-md="3" cols="12" class="pt-0">
              <v-checkbox
                v-model="agree"
                dense
                label="He leído la información de los plazos de entrega"
              ></v-checkbox>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col md="6" offset-md="3" cols="12">
              <v-btn
                type="submit"
                color="primary"
                block
                :disabled="searchDisabled()"
                @click="search()"
              >
                Buscar
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-show="showError">
            <v-col md="8" offset-md="2">
              <div class="error--text text-center">Paciente no encontrado</div>
            </v-col>
          </v-row>
          <v-divider v-if="showConditions == 'True'" class="ma-4"> </v-divider>
          <v-row v-if="showConditions == 'True'" class="mx-2">
            <v-col md="8" offset-md="2">
              <div class="text-h6">
                Tenga presente que los exámenes tienen un plazo de entrega:
              </div>
              <ul class="list-group">
                <li>
                  <div>
                    <b class="primary--text"
                      >Exámenes de sangre comunes y orina:
                    </b>
                    disponibles hasta <b>48 hrs hábiles </b> después de la toma
                    de la muestra (*)
                  </div>
                </li>
                <li>
                  <div>
                    <b class="primary--text"> Ecografías: </b> disponibles hasta
                    <b>72 hrs hábiles </b> después de realizado el examen.
                  </div>
                </li>
                <li>
                  <div>
                    <b class="primary--text"> Citologías: </b> disponibles hasta
                    <b>5 días hábiles </b> después de la toma de la muestra.
                  </div>
                </li>
                <li>
                  <div>
                    <b class="primary--text"> Biopsias: </b> disponibles hasta
                    <b>15 días hábiles </b> después de enviado el examen al
                    laboratorio
                  </div>
                </li>
                <li>
                  <div>
                    <b class="primary--text"> Cultivos: </b>por lo general
                    pueden demorar hasta
                    <b>10 días hábiles</b>
                  </div>
                </li>
              </ul>
              <div class="text-caption">
                (*) Exámenes de sangre específicos podrían tener plazos
                distintos y serán informados al momento de la toma de muestra
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios"
export default {
  props: {
    csrfToken: {
      type: String,
      default: null,
    },
    clientName: {
      type: String,
      default: "",
    },
    showConditions: {
      type: String,
      default: "False",
    },
  },
  data: function () {
    return {
      pet_code: null,
      agree: false,
      showError: false,
    }
  },
  watch: {
    pet_code() {
      if (this.showError) this.showError = false
    },
  },
  methods: {
    search() {
      axios({
        method: "post",
        url: "/emrs/datafiles/external",
        data: {
          pet_code: this.pet_code.trim(),
        },
        headers: { "X-CSRFToken": this.csrfToken },
      })
        .then((res) => {
          window.location.href =
            "/emrs/datafiles/" + res.data.pet_id + "/list-files"
        })
        .catch(() => {
          this.showError = true
        })
    },
    searchDisabled() {
      if (this.showConditions == "True") {
        return !this.pet_code || !this.agree
      }
      return !this.pet_code
    },
  },
}
</script>
