import axios from "axios"
import Cookies from "js-cookie"
import store from "../store"

var csrftoken = Cookies.get("csrftoken")

export function axiosHelper(
  method,
  url,
  params = {},
  data = {},
  callback = () => {},
  errorCallback = null
) {
  if (!store.state.profile) {
    axios
      .get("/emrs/api/profiles/me", {})
      .then((res) => {
        store.dispatch("setProfile", res.data)
        _axiosHelper(method, url, params, data, callback, errorCallback)
      })
      .catch(function (error) {
        console.log(error)
      })
  } else {
    _axiosHelper(method, url, params, data, callback, errorCallback)
  }
}

export function _axiosHelper(
  method,
  url,
  params = {},
  data = {},
  callback = () => {},
  errorCallback = null
) {
  let token
  if (token) {
    token.cancel()
  }
  token = axios.CancelToken.source()
  axios({
    method,
    url,
    data,
    params,
    headers: { "X-CSRFToken": csrftoken },
    cancelToken: token.token,
  })
    .then((res) => {
      const url = res.request.responseURL
      if (url.includes("login") && url.includes("next")) {
        var notification = {
          text: "La sesión del usuario ha expirado",
          color: "error",
        }

        store
          .dispatch("displayNotification", notification)
          .then(() => (window.location.href = url))
      }
      callback(res)
    })
    .catch((error) => {
      var notification = {}
      if (error.response && error.response.status === 403) {
        if (method == "get") {
          notification = {
            text: "Usuario sin autorización para acceder a los datos",
            color: "error",
          }
        } else {
          notification = {
            text: "Usuario sin autorización para realizar la operación",
            color: "error",
          }
        }
        store.dispatch("displayNotification", notification)
        console.log(error)
        return
      }
      if (errorCallback) {
        errorCallback(error)
      } else {
        notification = {
          text: error.response.data.error_msg,
          color: "error",
        }
        store.dispatch("displayNotification", notification)
      }
      console.log(error)
    })
}
