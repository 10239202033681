<template>
  <v-container style="max-width: 800" class="mx-auto mt-5">
    <div>
      <bordered-sheet color="black">
        <v-row justify="center">
          <img src="/static/logo_phisi.svg" style="width: 15%" class="mt-4" />
        </v-row>
        <v-row>
          <v-col>
            <div class="text-h4 text-center">PhisiEMR</div>
            <div class="text--secondary text-center text-h5">
              Sistema de Fichas Clínicas Veterinarias
            </div>
            <v-divider class="ma-4"> </v-divider>
            <v-form method="post" :action="loginUrl">
              <input
                type="hidden"
                name="csrfmiddlewaretoken"
                :value="csrfToken"
              />
              <v-row class="mx-2">
                <v-col md="6" offset-md="3">
                  <v-text-field
                    v-model="username"
                    :label="labels.username"
                    name="username"
                    prepend-icon="mdi-account-circle"
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="password"
                    :label="labels.password"
                    name="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    required
                    @click:append="show1 = !show1"
                  >
                  </v-text-field>
                  <v-select
                    name="client"
                    :items="clients"
                    :label="labels.client"
                    prepend-icon="mdi-domain"
                    required
                  ></v-select>

                  <v-alert
                    v-for="message in messages"
                    :key="message"
                    type="error"
                    dense
                    dismissable
                  >
                    message
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" offset-md="3">
                  <v-btn
                    type="submit"
                    value="login"
                    block
                    color="primary"
                    class="mb-3"
                  >
                    <v-icon left> mdi-lock </v-icon>
                    Login
                  </v-btn>
                </v-col>
                <input type="hidden" name="next" :value="next" />
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </bordered-sheet>
    </div>
  </v-container>
</template>
<script>
import {
  VContainer,
  VRow,
  VCol,
  VForm,
  VIcon,
  VTextField,
  VSelect,
} from "vuetify/lib"
import BorderedSheet from "../../components/BorderedSheet.vue"
export default {
  components: {
    VContainer,
    VRow,
    VCol,
    VForm,
    VIcon,
    VTextField,
    VSelect,
    BorderedSheet,
  },
  props: {
    csrfToken: {
      type: String,
      default: null,
    },
    loginUrl: {
      type: String,
      default: null,
    },
    next: {
      type: String,
      default: null,
    },
    labels: {
      type: Object,
      default: function () {
        return {
          username: "",
          password: "",
          client: "",
        }
      },
    },
    clients: {
      type: Array,
      default: function () {
        return []
      },
    },
    messages: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data: function () {
    return {
      username: "",
      password: "",
      show1: false,
    }
  },
}
</script>
