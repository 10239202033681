import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: "/",
      name: "main-index",
      component: () => import("./views/main_index/MainIndex.vue"),
    },
    {
      path: "/help/emrs",
      name: "help_emrs",
      component: () => import("./views/help/HelpEmrs.vue"),
    },
    {
      path: "/help/erp",
      name: "help_erp",
      component: () => import("./views/help/HelpErp.vue"),
    },
    {
      path: "/help/users",
      name: "help_users",
      component: () => import("./views/help/HelpUsers.vue"),
    },
    {
      path: "/help/news",
      name: "help_news",
      component: () => import("./views/help/HelpNews.vue"),
    },
    {
      path: "/pets",
      name: "pets-index",
      component: () => import("./views/emrs/Pet/PetIndex.vue"),
      props: true,
    },
    {
      path: "/pets/add",
      name: "pets-add",
      component: () => import("./views/emrs/Pet/PetCreate.vue"),
    },
    {
      path: "/pets/:petId/edit",
      name: "pets-edit",
      component: () => import("./views/emrs/Pet/PetEdit.vue"),
      props: true,
    },
    {
      path: "/pets/:petId/records",
      name: "pets-records",
      component: () => import("./views/emrs/Record/PetRecords.vue"),
      props: true,
    },
    {
      path: "/pets/:petId/records/add",
      name: "pets-add-record",
      component: () => import("./views/emrs/Record/RecordForm.vue"),
      props: true,
    },
    {
      path: "/pets/:petId/datafiles",
      name: "pets-datafiles",
      component: () => import("./views/emrs/DataFile/PetDataFiles.vue"),
      props: true,
    },
    {
      path: "/pets/:petId/datafiles/add",
      name: "pets-add-datafile",
      component: () => import("./views/emrs/DataFile/DataFileForm.vue"),
      props: true,
    },
    {
      path: "/owners/",
      name: "owners-index",
      component: () => import("./views/emrs/Owner/OwnerIndex.vue"),
      props: true,
    },
    {
      path: "/owners/add",
      name: "owners-add",
      component: () => import("./views/emrs/Owner/OwnerCreate.vue"),
    },
    {
      path: "/owners/:ownerId/edit",
      name: "owners-edit",
      component: () => import("./views/emrs/Owner/OwnerEdit.vue"),
      props: true,
    },
    {
      path: "/owners/:ownerId/add-pet",
      name: "owners-add-pet",
      component: () => import("./views/emrs/Pet/PetCreate.vue"),
      props: true,
    },
    {
      path: "/profiles/:profileId/details",
      name: "profile-details",
      component: () => import("./views/emrs/ProfileDetails/ProfileDetails.vue"),
      props: true,
    },
    {
      path: "/records/:recordId/update",
      name: "records-update",
      component: () => import("./views/emrs/Record/RecordForm.vue"),
      props: true,
    },
    {
      path: "/datafiles/:datafileId/update",
      name: "datafiles-update",
      component: () => import("./views/emrs/DataFile/DataFileForm.vue"),
      props: true,
    },
    {
      path: "/hospitalizations/",
      name: "hospitalizations-overview",
      component: () => import("./views/emrs/Hospitalization/Index.vue"),
    },
    {
      path: "/hospitalizations/:hospitalizationId/details",
      name: "hospitalization-details",
      component: () => import("./views/emrs/Hospitalization/Details.vue"),
      props: true,
    },
    {
      path: "/hospitalizations/:hospitalizationId/add-discharge",
      name: "hospitalization-discharge-add",
      component: () => import("./views/emrs/Hospitalization/DischargeForm.vue"),
      props: true,
    },
    {
      path: "/hospitalizations/:hospitalizationId/edit-discharge",
      name: "hospitalization-discharge-edit",
      component: () => import("./views/emrs/Hospitalization/DischargeForm.vue"),
      props: true,
    },
    {
      path: "/hospitalizations/:hospitalizationId/add-evolution",
      name: "hospitalization-evolution-add",
      component: () => import("./views/emrs/Hospitalization/EvolutionForm.vue"),
      props: true,
    },
    {
      path: "/hospitalizations/:hospitalizationId/edit-evolution/:evolutionDataId",
      name: "hospitalization-evolution-edit",
      component: () => import("./views/emrs/Hospitalization/EvolutionForm.vue"),
      props: true,
    },
    {
      path: "/hospitalizations/scheduling",
      name: "hospitalizations-scheduling",
      component: () => import("./views/emrs/Hospitalization/Schedule.vue"),
    },
    {
      path: "/hospitalizations/cages",
      name: "hospitalizations-cages",
      component: () => import("./views/emrs/Cage/CageIndex.vue"),
    },
    {
      path: "/quotes/",
      name: "quote-index",
      component: () => import("./views/erp/Quote/QuoteIndex.vue"),
      props: true,
    },
    {
      path: "/quotes/add",
      name: "quote-create",
      component: () => import("./views/erp/Quote/QuoteForm.vue"),
    },
    {
      path: "/quotes/:quoteId/edit",
      name: "quote-edit",
      component: () => import("./views/erp/Quote/QuoteForm.vue"),
      props: true,
    },

    {
      path: "/sales/consultation",
      name: "sales-consultation-index",
      component: () => import("./views/erp/Sale/SaleConsultationIndex.vue"),
      props: true,
    },
    {
      path: "/sales/hospitalization",
      name: "sales-hospitalization-index",
      component: () => import("./views/erp/Sale/SaleHospitalizationIndex.vue"),
      props: true,
    },
    {
      path: "/sales/add",
      name: "sales-create",
      component: () => import("./views/erp/Sale/SaleForm.vue"),
      props: true,
    },
    {
      path: "/sales/:saleId/edit",
      name: "sales-edit",
      component: () => import("./views/erp/Sale/SaleForm.vue"),
      props: true,
    },

    {
      path: "/templates/",
      name: "templates-index",
      component: () => import("./views/erp/Template/TemplateIndex.vue"),
    },
    {
      path: "/templates/add",
      name: "templates-create",
      component: () => import("./views/erp/Template/TemplateForm.vue"),
    },
    {
      path: "/templates/:templateId/edit",
      name: "templates-edit",
      component: () => import("./views/erp/Template/TemplateForm.vue"),
      props: true,
    },

    {
      path: "/payments/",
      name: "payments-index",
      component: () => import("./views/erp/Payment/PaymentIndex.vue"),
    },
    {
      path: "/inventory/",
      name: "inventory-index",
      component: () => import("./views/erp/Inventory/InventoryIndex.vue"),
      props: true,
    },
    {
      path: "/reports/records",
      name: "reports-records",
      component: () => import("./views/emrs/Reports/RecordReport.vue"),
    },
    {
      path: "/reports/sales",
      name: "reports-sales",
      component: () => import("./views/erp/Reports/SalesReport.vue"),
    },
  ],
})
