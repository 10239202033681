<template>
  <v-sheet
    outlined
    v-bind="$attrs"
    class="rounded pa-1"
    :style="`border-color: black; border-bottom: 8px solid ${color} !important; `"
  >
    <slot></slot>
  </v-sheet>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "no-color",
    },
  },
}
</script>
