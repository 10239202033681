var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-5",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"grey darken-4 white--text"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('span',{staticStyle:{"font-size":"large"}}),_vm._v(" \n          "+_vm._s(_vm.pet.name)+"\n        ")])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"mb-3 mt-3"},[_c('v-row',[_c('v-col',{attrs:{"md":"2","align":"center","cols":"12"}},[_c('v-img',{staticClass:"d-none d-md-flex",attrs:{"src":_vm.imgUrl,"contain":""}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"10","align":"center","cols":"12"}},[_c('v-row',{staticClass:"d-sm-flex d-md-none",attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Especie:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v("\n                "+_vm._s(_vm.breed)+"\n              ")])])],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Sexo:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v("\n                "+_vm._s(_vm.pet.gender == "FEMALE" ? "Hembra" : "Macho")+"\n              ")])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Nº de Ficha:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v(_vm._s(_vm.pet.clinical_id
                    ? _vm.pet.clinical_id.toString().padStart(5, "0")
                    : "N/D"))])])])],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Raza:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v("\n                "+_vm._s(_vm.pet.breed)+"\n              ")])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Código:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.pet.short_id))])])],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Edad:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v("\n                "+_vm._s(_vm.pet.age
                    ? _vm.pet.age[0] + " años, " + _vm.pet.age[1] + " meses"
                    : "N/D")+"\n              ")])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Peso:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.pet.weight))])])],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Color:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.pet.color))])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Chip:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.pet.identification))])])],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Esterilizado:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v("\n                "+_vm._s(_vm.pet.sterilized ? "Sí" : "No")+"\n              ")])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_c('b',[_vm._v("Tutor:")])])]),_vm._v(" "),_c('v-col',{attrs:{"md":"3","align":"left","cols":"6"}},[_c('div',{staticClass:"text-body-1"},[_vm._v("\n                "+_vm._s(_vm.pet &&
                  _vm.pet.owner &&
                  (_vm.pet.owner.first_name || _vm.pet.owner.last_name)
                    ? _vm.pet.owner.first_name + " " + _vm.pet.owner.last_name
                    : "---")+"\n              ")])])],1)],1)],1)],1)],1),_vm._v(" "),_c('v-card',{staticClass:"mt-5",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('span',{staticClass:"fas fa-vials la-lg"}),_vm._v(" \n          Exámenes\n        ")])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"mt-3"},[(_vm.datafiles.length)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"grey darken-4"},[_c('tr',[_c('th',{staticClass:"text-subtitle-1 white--text",attrs:{"scope":"col"}},[_vm._v("Fecha")]),_vm._v(" "),_c('th',{staticClass:"text-subtitle-1 white--text",attrs:{"scope":"col"}},[_vm._v("\n                Descripción\n              ")]),_vm._v(" "),_c('th',{staticClass:"text-subtitle-1 white--text",attrs:{"scope":"col"}})])]),_vm._v(" "),_c('tbody',_vm._l((_vm.datafiles),function(datafile){return _c('tr',{key:datafile.id},[_c('td',[_vm._v("\n                "+_vm._s(_vm.dateToUTCStr(new Date(datafile.created_at)))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s(datafile.description)+"\n              ")]),_vm._v(" "),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","color":"primary","href":datafile.link,"target":"_blank","rel":"noopener noreferrer"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"fas fa-download la-2x"})])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Descargar")])])],1)])}),0)]},proxy:true}],null,false,3815479894)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }