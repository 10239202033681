// plugins/vuetify.js

import Vue from "vue"
import Vuetify, {
  VApp,
  VAppBar,
  VNavigationDrawer,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VDivider,
  VListItemGroup,
  VListItemIcon,
  VListGroup,
  VAppBarNavIcon,
  VToolbarTitle,
  VToolbarItems,
  VMenu,
  VBtn,
  VList,
  VContainer,
  VFooter,
  VCol,
  VMain,
  VIcon,
  VSpacer,
} from "vuetify/lib"

Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VNavigationDrawer,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VDivider,
    VListItemGroup,
    VListItemIcon,
    VListGroup,
    VAppBarNavIcon,
    VToolbarTitle,
    VToolbarItems,
    VMenu,
    VBtn,
    VList,
    VContainer,
    VFooter,
    VCol,
    VMain,
    VSpacer,
    VIcon,
  },
})

const opts = {}

export default new Vuetify(opts)
