<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h4 text-center">Cambio de contraseña</div>
        <v-form method="post" :action="submitUrl">
          <input type="hidden" name="csrfmiddlewaretoken" :value="csrfToken" />
          <v-container>
            <v-row>
              <v-col md="4" offset-md="4">
                <v-text-field
                  v-model="old_password"
                  :label="labels.old_password"
                  name="old_password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  required
                  :error-messages="errorMessagesOldPass"
                  @click:append="show1 = !show1"
                >
                </v-text-field>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="new_password1"
                      :label="labels.new_password1"
                      name="new_password1"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      prepend-icon="mdi-lock"
                      required
                      :error-messages="errorMessagesNewPass1"
                      v-bind="attrs"
                      @click:append="show2 = !show2"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <div v-for="text in helpText" :key="text" class="text-body-2">
                    {{ text }}
                  </div>
                </v-tooltip>

                <v-text-field
                  v-model="new_password2"
                  :label="labels.new_password2"
                  name="new_password2"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show3 ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  required
                  :error-messages="errorMessagesNewPass2"
                  @click:append="show3 = !show3"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4" offset-md="4">
                <v-btn type="submit" color="primary" block>
                  <v-icon left> mdi-lock </v-icon>
                  Cambiar Contraseña
                </v-btn>
                <input type="hidden" name="next" :value="next" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    errorMessagesOldPass: {
      type: String,
      default: "",
    },
    errorMessagesNewPass1: {
      type: String,
      default: "",
    },
    errorMessagesNewPass2: {
      type: String,
      default: null,
    },
    csrfToken: {
      type: String,
      default: null,
    },
    next: {
      type: String,
      default: null,
    },
    submitUrl: {
      type: String,
      default: null,
    },
    helpText: {
      type: Array,
      default() {
        return []
      },
    },
    labels: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data: function () {
    return {
      old_password: null,
      new_password1: null,
      new_password2: null,
      show1: false,
      show2: false,
      show3: false,
    }
  },
}
</script>
