<template>
  <v-container>
    <div class="text-h4 text-center mt-5">Cambio de contraseña realizado!</div>
    <v-row>
      <v-col md="4" offset-md="4">
        <v-btn href="/" color="primary" block>
          <v-icon left> mdi-home </v-icon>
          Volver al inicio
        </v-btn>
        <input type="hidden" name="next" :value="[[next]]" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    next: {
      type: String,
      default: null,
    },
  },
}
</script>
