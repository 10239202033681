<template>
  <div>
    <v-card outlined class="mt-5">
      <v-card-title class="grey darken-4 white--text">
        <v-row align="center">
          <v-col md="6" cols="12">
            <span style="font-size: large"></span>&nbsp;
            {{ pet.name }}
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="mb-3 mt-3">
        <v-row>
          <v-col md="2" align="center" cols="12">
            <v-img :src="imgUrl" contain class="d-none d-md-flex"></v-img>
          </v-col>
          <v-col md="10" align="center" cols="12">
            <v-row align="center" class="d-sm-flex d-md-none" dense>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Especie:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">
                  {{ breed }}
                </div>
              </v-col>
            </v-row>
            <v-row align="center" dense>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Sexo:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">
                  {{ pet.gender == "FEMALE" ? "Hembra" : "Macho" }}
                </div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Nº de Ficha:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">
                  <b>{{
                    pet.clinical_id
                      ? pet.clinical_id.toString().padStart(5, "0")
                      : "N/D"
                  }}</b>
                </div>
              </v-col>
            </v-row>
            <v-row align="center" dense>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Raza:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">
                  {{ pet.breed }}
                </div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Código:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">{{ pet.short_id }}</div>
              </v-col>
            </v-row>
            <v-row align="center" dense>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Edad:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">
                  {{
                    pet.age
                      ? pet.age[0] + " años, " + pet.age[1] + " meses"
                      : "N/D"
                  }}
                </div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Peso:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">{{ pet.weight }}</div>
              </v-col>
            </v-row>
            <v-row align="center" dense>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Color:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">{{ pet.color }}</div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Chip:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">{{ pet.identification }}</div>
              </v-col>
            </v-row>
            <v-row align="center" dense>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Esterilizado:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">
                  {{ pet.sterilized ? "Sí" : "No" }}
                </div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1"><b>Tutor:</b></div>
              </v-col>
              <v-col md="3" align="left" cols="6">
                <div class="text-body-1">
                  {{
                    pet &&
                    pet.owner &&
                    (pet.owner.first_name || pet.owner.last_name)
                      ? pet.owner.first_name + " " + pet.owner.last_name
                      : "---"
                  }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card outlined class="mt-5">
      <v-card-title>
        <v-row align="center">
          <v-col md="3" cols="12">
            <span class="fas fa-vials la-lg"></span>&nbsp;
            Exámenes
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-simple-table v-if="datafiles.length">
          <template #default>
            <thead class="grey darken-4">
              <tr>
                <th class="text-subtitle-1 white--text" scope="col">Fecha</th>
                <th class="text-subtitle-1 white--text" scope="col">
                  Descripción
                </th>
                <th class="text-subtitle-1 white--text" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="datafile in datafiles" :key="datafile.id">
                <td>
                  {{ dateToUTCStr(new Date(datafile.created_at)) }}
                </td>
                <td>
                  {{ datafile.description }}
                </td>
                <td>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        fab
                        x-small
                        color="primary"
                        v-bind="attrs"
                        :href="datafile.link"
                        target="_blank"
                        rel="noopener noreferrer"
                        v-on="on"
                      >
                        <span class="fas fa-download la-2x"></span>
                      </v-btn>
                    </template>
                    <span>Descargar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "axios"
import { dateToUTCStr } from "@/helpers/dateFormatting.js"
export default {
  props: {
    petId: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      datafiles: [],
      pet: {},
    }
  },
  computed: {
    imgUrl() {
      if (!this.pet) return null
      else if (this.pet.species == "CAT") return "/static/cat_small.png"
      else if (this.pet.species == "DOG") return "/static/dog_small.png"
      return "/static/exotic_small.png"
    },
    breed() {
      if (!this.pet) return null
      else if (this.pet.species == "CAT") return "Gato"
      else if (this.pet.species == "DOG") return "Perro"
      return "Otro"
    },
  },
  mounted() {
    this.getDataFiles()
  },
  methods: {
    dateToUTCStr(date) {
      return dateToUTCStr(date)
    },
    getDataFiles() {
      axios({
        method: "get",
        url: "/emrs/datafiles/" + this.petId + "/get-data",
        data: {
          pet_id: this.petId,
        },
        headers: { "X-CSRFToken": this.csrfToken },
      }).then((res) => {
        this.pet = res.data.pet
        this.datafiles = res.data.datafiles
      })
    },
  },
}
</script>
