export const messages = {
  es: {
    ANESTHESIA: "ANESTESIA",
    BRONCHOPULMONARY: "BRONCOPULMONAR",
    CANCELED: "CANCELADO",
    CARDIOLOGY: "CARDIOLOGÍA",
    DECEASED_AT_HOSPITAL: "FALLECIDO EN HOSPITAL",
    DENTISTRY: "ODONTOLOGÍA",
    DERMATOLOGY: "DERMATOLOGÍA",
    DISCHARGED: "ALTA HOSPITALIZACIÓN",
    ECOGRAPHY: "ECOGRAFÍA",
    EMERGENCY_EUTHANASIA: "EUTANASIA DE EMERGENCIA",
    ENDOCRINOLOGY: "ENDOCRINOLOGÍA",
    EXOTIC: "MEDICINA ANIMALES EXÓTICOS",
    FELINE_MEDICINE: "MEDICINA FELINA",
    GASTROENTEROLOGY: "GASTROENTEROLOGÍA",
    HOMECARE: "ATENCIÓN A DOMICILIO",
    IN_HOSPITAL: "EN HOSPITAL",
    INTERNAL_MEDICINE: "MEDICINA GENERAL",
    NEFROUROLOGY: "NEFROUROLOGÍA",
    NEUROLOGY: "NEUROLOGÍA",
    ODONTOLOGY: "ODONTOLOGÍA",
    ONCOLOGY: "ONCOLOGÍA",
    OPHTHALMOLOGY: "OFTALMOLOGÍA",
    OTHER: "OTRO",
    PHYSICAL_THERAPY: "TERAPIA FÍSICA",
    SCHEDULED_EUTHANASIA: "EUTANASIA PROGRAMADA",
    SCHEDULED: "PROGRAMADO",
    SOLICITED_DISCHARGE: "ALTA SOLICITADA",
    SURGERY: "CIRUGÍA",
    TEMPORAL_DISCHARGE: "ALTA TEMPORAL",
    TRAUMATOLOGY: "TRAUMATOLOGÍA",
    ETHOLOGY: "ETOLOGÍA",
    PAIN_MANAGEMENT: "MANEJO DEL DOLOR",
    GERIATRICS: "GERIATRÍA",
  },
}
