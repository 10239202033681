import format from "date-fns/format"
import es from "date-fns/locale/es"

export function dateToUTCStr(date, iso = false) {
  var utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  if (iso) return format(utc, "yyyy-MM-dd", { locale: es })
  return format(utc, "dd MMM yyyy", { locale: es })
}

export function dateToLocalStr(date, iso = false) {
  if (iso) return format(date, "yyyy-MM-dd", { locale: es })
  return format(date, "dd MMM yyyy", { locale: es })
}

export function datetimeToUTCStr(date, iso = false) {
  var utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  if (iso) return format(utc, "yyyy-MM-dd HH:mm", { locale: es })
  return format(utc, "dd MMM yyyy HH:mm", { locale: es })
}

export function datetimeToLocalStr(date, iso = false) {
  if (iso) return format(date, "yyyy-MM-dd HH:mm", { locale: es })

  return format(date, "dd MMM yyyy HH:mm", { locale: es })
}

export function UTCStrToDate(dateStr) {
  var date = new Date(dateStr)
  return date
}

export function isoDateStrToLocalStr(dateStr, iso = false) {
  var date = new Date(dateStr)
  if (iso) return format(date, "yyyy-MM-dd", { locale: es })
  return format(date, "dd MMM yyyy", { locale: es })
}

export function isoDatetimeStrToLocalStr(dateStr, iso = false) {
  var date = new Date(dateStr)
  if (iso) return format(date, "yyyy-MM-dd kk:mm", { locale: es })
  return format(date, "dd MMM yyyy kk:mm", { locale: es })
}

export function isoDateStrToUTCStr(dateStr) {
  var date = new Date(dateStr)
  var utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  return format(utc, "dd MMM yyyy", { locale: es })
}

export function isoDateStrToUTCDate(dateStr) {
  var date = new Date(dateStr)
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}

export function isoDateStrToLocalDate(dateStr) {
  return new Date(dateStr)
}

export function isoDateStrToLocalTime(dateStr) {
  var date = new Date(dateStr)
  return format(date, "kk:mm", { locale: es })
}

export function datetimeToUTCTimeStr(date) {
  var utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  return format(utc, "kk:mm", { locale: es })
}

export function datetimeToLocalTimeStr(date) {
  return format(date, "kk:mm", { locale: es })
}
