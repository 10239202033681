<template>
  <div>
    <v-snackbar
      v-model="notificationSnackbar"
      color="info"
      multi-line
      timeout="-1"
    >
      El sistema ha sido actualizado. Por favor
      <strong> guarde su trabajo </strong> y recargue la página a la brevedad.
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="reloadPage()"> Recargar </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex"
export default {
  computed: {
    notificationSnackbar: {
      get() {
        return this.$store.state.notificationReloadSnackbar
      },
      set() {
        this.$store.dispatch("resetReloadNotification")
      },
    },
    ...mapState(["notificationText", "notificationColor"]),
  },
  methods: {
    reloadPage() {
      this.notificationSnackbar = false
      window.location.reload()
    },
  },
}
</script>
