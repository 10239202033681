import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    notificationSnackbar: false,
    notificationColor: "success",
    notificationText: "",
    notificationReloadSnackbar: false,
    profile: null,
    version: null,
  },

  mutations: {
    DISPLAY_NOTIFICATION(state, notification) {
      state.notificationText = notification.text
      state.notificationColor = notification.color
      state.notificationSnackbar = true
    },
    RESET_NOTIFICATION(state) {
      state.notificationSnackbar = false
    },
    DISPLAY_RELOAD_NOTIFICATION(state) {
      state.notificationReloadSnackbar = true
    },
    RESET_RELOAD_NOTIFICATION(state) {
      state.notificationReloadSnackbar = false
    },
    SET_PROFILE(state, profile) {
      state.profile = profile
    },
    SET_VERSION(state, version) {
      state.version = version
    },
  },

  actions: {
    displayNotification({ commit }, notification) {
      commit("DISPLAY_NOTIFICATION", notification)
    },
    resetNotification({ commit }) {
      commit("RESET_NOTIFICATION")
    },
    displayReloadNotification({ commit }) {
      commit("DISPLAY_RELOAD_NOTIFICATION")
    },
    resetReloadNotification({ commit }) {
      commit("RESET_RELOAD_NOTIFICATION")
    },
    setProfile({ commit }, profile) {
      commit("SET_PROFILE", profile)
    },
    setVersion({ commit }, version) {
      commit("SET_VERSION", version)
    },
  },
})
