<template>
  <div>
    <v-snackbar
      v-model="notificationSnackbar"
      :timeout="notificationTimeout"
      :color="notificationColor"
    >
      {{ notificationText }}

      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="notificationSnackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex"
export default {
  data: () => ({
    notificationTimeout: 7000,
  }),
  computed: {
    notificationSnackbar: {
      get() {
        return this.$store.state.notificationSnackbar
      },
      set() {
        this.$store.dispatch("resetNotification")
      },
    },
    ...mapState(["notificationText", "notificationColor"]),
  },
}
</script>
