/* eslint-disable vue/no-unused-properties */
import Vue from "vue"
import vuetify from "./plugins/vuetify"
import router from "./router"
import store from "./store"
import { axiosHelper } from "./services/base.js"
import VueI18n from "vue-i18n"
import { messages } from "./translations.js"

// AUTH
Vue.component("Login", require("./views/auth/Login.vue").default)
Vue.component(
  "PasswordChangeOk",
  require("./views/auth/PasswordChangeOK.vue").default
)
Vue.component(
  "PasswordChange",
  require("./views/auth/PasswordChange.vue").default
)
// Notifications
Vue.component("Notification", require("./components/Notification.vue").default)
Vue.component(
  "NotificationReload",
  require("./components/NotificationReload.vue").default
)

// External Datafiles
Vue.component("PetSearch", require("./views/external/PetSearch.vue").default)

Vue.component(
  "PetDatafilesExternal",
  require("./views/external/PetDataFiles.vue").default
)
Vue.mixin({
  data: function () {
    return {
      get FE_FEATURE_FLAG() {
        return process.env.MIX_FE_FEATURE_FLAG == "True"
      },
    }
  },
  methods: {
    goBackSafe() {
      if (history.length > 1) {
        router.back()
        return
      }
      router.push({ name: "main-index" })
    },
  },
})
Vue.use(VueI18n)
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "es", // set locale
  messages, // set locale messages
})

require("./fake.scss") // workaround to load styles tag webpack
new Vue({
  delimiters: ["[[", "]]"],
  vuetify,
  router,
  store,
  i18n,
  data: {
    drawer: false,
    group: null,
  },
  mounted: function () {
    window.setInterval(() => {
      this.checkNewVersion()
    }, 1000 * 60 * 60 * 6)
  },
  methods: {
    checkNewVersion() {
      if (!store.state.profile) {
        return
      }
      var callback = (res) => {
        var previous_version = store.state.version
        if (!previous_version) {
          store.dispatch("setVersion", res.data.version)
          return
        }
        if (previous_version && previous_version !== res.data.version) {
          console.log(res.data.version)
          store.dispatch("setVersion", res.data.version)
          store.dispatch("displayReloadNotification")
          return
        }
      }
      var callbackError = (error) => {
        console.log(error)
      }
      axiosHelper(
        "get",
        "/emrs/api/version",
        null,
        null,
        callback,
        callbackError
      )
    },
  },
}).$mount("#app")
